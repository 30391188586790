import React, { useEffect, useState } from "react";
import Header from "../../layouts/Header";
import Footer from "../../layouts/Footer";
import { Link } from "react-router-dom";
import { Button, Card, Col, Nav, Offcanvas, Form, OverlayTrigger, Modal, Row, Badge, Tooltip } from "react-bootstrap";
import { Grid } from "gridjs-react";
import Avatar from "../../components/Avatar";

import img1 from "../../assets/img/img1.jpg";

export default function AdvertiserPpc() {

  const [showBottom, setShowBottom] = useState(false);
  const handleCloseBottom = () => setShowBottom(false);
  const handleShowBottom = () => setShowBottom(true);
  
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);


  const currentSkin = (localStorage.getItem('skin-mode')) ? 'dark' : '';
  const [skin, setSkin] = useState(currentSkin);

  const switchSkin = (skin) => {
    if (skin === 'dark') {
      const btnWhite = document.getElementsByClassName('btn-white');

      for (const btn of btnWhite) {
        btn.classList.add('btn-outline-primary');
        btn.classList.remove('btn-white');
      }
    } else {
      const btnOutlinePrimary = document.getElementsByClassName('btn-outline-primary');

      for (const btn of btnOutlinePrimary) {
        btn.classList.remove('btn-outline-primary');
        btn.classList.add('btn-white');
      }
    }
  };

  switchSkin(skin);

  useEffect(() => {
    switchSkin(skin);
  }, [skin]);

  return (
    <React.Fragment>
      <Header onSkin={setSkin} />
      <div className="main main-app p-3 p-lg-4">

        <div className="d-flex align-items-center justify-content-between mb-4">
          <div>
            <ol className="breadcrumb fs-sm mb-1">
              <li className="breadcrumb-item"><Link href="#">ADV Home</Link></li>
              <li className="breadcrumb-item">ABC Bank</li>
              <li className="breadcrumb-item active" aria-current="page">PPC Auctions</li>
            </ol>
            <h4 className="main-title mb-0">Pay-Per-Click (PPC) Auctions</h4>
          </div>

          <Nav as="nav" className="nav-icon nav-icon-lg">
            <OverlayTrigger overlay={<Tooltip>Print</Tooltip>}>
              <Nav.Link href=""><i className="ri-printer-line"></i></Nav.Link>
            </OverlayTrigger>
            <OverlayTrigger overlay={<Tooltip>Report</Tooltip>}>
              <Nav.Link href=""><i className="ri-bar-chart-2-line"></i></Nav.Link>
            </OverlayTrigger>
          </Nav>

        </div>

        <Row className="g-3">
          <Col md="12" xl="4">
            <Card className="card-one">
              <Card.Body>
                <Row>
                  <Col xs="12">
                    <div className="pull-left" style={{ float: 'left', marginRight: '14px' }}>
                    <Avatar img={img1} size="xl" shape="rounded"  />
                    </div>
                    <h3 className="card-value fw-bold mb-0">ABC Bank</h3>
                    <label className="card-title fw-medium text-dark mb-1">Promoted Product</label>
                    <span className="d-block text-muted fs-11 ff-secondary lh-4">Categories: <b>Trade Finance</b>, <b>US</b>, <b>+2 more</b></span>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
          <Col md="6" xl="4">
            <OverlayTrigger
              placement="top"
              overlay={
                <Tooltip>
                  Please <strong>contact us</strong> to alter or cancel this campaign.
                </Tooltip>
              }
            >
              <Card className="card-one">
                <Card.Body>
                  <Row>
                    <Col xs="12">
                      <h3 className="card-value fw-bold text-success mb-0"><b>ACTIVE</b></h3>
                      <label className="card-title text-dark mb-1">Campaign Status</label>
                      <span className="d-block text-muted fs-11 ff-secondary lh-4">Your next billing day: <b>1. Sep</b> (in 28 days)</span>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </OverlayTrigger>
          </Col>
          <Col md="6" xl="4">
            <OverlayTrigger overlay={<Tooltip>Click to adjust your budget</Tooltip>}>
              <Card className="card-one cursor-pointer" onClick={handleShowBottom}>
                <Card.Body>
                  <Row>
                    <Col xs="12">
                      <h3 className="card-value mb-0"><b>$40,000.00</b></h3>
                      <label className="card-title fw-medium text-dark mb-1">Monthly Budget</label>
                      <span className="d-block text-muted fs-11 ff-secondary lh-4">Your spend this month: <b>$24,543.00</b></span>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </OverlayTrigger>
          </Col>

  
          <Col md="12">
            <Card className="card-one">
              <Card.Header>
                <Card.Title as="h6">Live PPC Auctions</Card.Title>
                <Nav className="nav-icon nav-icon-sm ms-auto">
                  <Nav.Link href=""><i className="ri-refresh-line"></i></Nav.Link>
                  <Nav.Link href=""><i className="ri-more-2-fill"></i></Nav.Link>
                </Nav>
              </Card.Header>
              <Card.Body>
              <Modal show={show} onHide={handleClose} centered>
                <Modal.Header closeButton>
                  <Modal.Title>Top 5 Trade Finance Providers</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <Row className="mb-3">
                    <Col sm="12">
                      <Form.Label htmlFor="blockCpc" className="col-form-label">Inventory URL(s)</Form.Label><br/>
                      <Link href="https://revbox.co/sample-inventory"><Badge bg="primary">https://revbox.co/sample-inventory</Badge></Link>
                    </Col>
                    <Col sm="12">
                      <div className="divider mt-4"><span>Bid Management</span></div>
                    </Col>
                    <Col sm="12">
                      <Form>
                        <Row className="">
                          <Col sm="4">
                            <Form.Label htmlFor="blockCpc" className="col-form-label">Your Bid</Form.Label>
                            <Form.Control type="number" id="blockCpc" placeholder="$8.00" />
                          </Col>
                          <Col sm="8">
                            <Card className="mt-2">
                              <Card.Body className="card-modal">
                                <Row className="justify-content-end text-start text-muted">
                                  <Col xl="4">Est. Pos.:<br/><span className="text-success fw-bold">#3</span></Col>
                                  <Col xl="4">Min Bid:<br/><span className=" text-muted fw-bold">$8.00</span></Col>
                                  <Col xl="4">Top Bid:<br/><span className="text-muted fw-bold">$32.00</span></Col>
                                </Row>
                              </Card.Body>
                            </Card>
                          </Col>
                        </Row>
                        <Row className="mt-2">
                          <Col sm="12">
                          <div className="divider"><span>Customization</span></div>
                          </Col>
                          <Col sm="12">
                            <Form.Label htmlFor="landingPageLink">Landing Page URL</Form.Label>
                            <Form.Control type="url" id="landingPageLink" placeholder="https://example.com/langing-page" />
                          </Col>
                        </Row>
                      </Form>
                    </Col>
                  </Row>
                </Modal.Body>
                <Modal.Footer>
                  <Button variant="secondary" onClick={handleClose}>
                    Close
                  </Button>
                  <Button variant="primary" onClick={handleClose}>
                    Save Bid
                  </Button>
                </Modal.Footer>
              </Modal>
                <Offcanvas show={showBottom} onHide={handleCloseBottom} placement="bottom">
                  <Offcanvas.Header closeButton>
                    <Offcanvas.Title className="m-auto">ABC Bank - Monthly Budget</Offcanvas.Title>
                  </Offcanvas.Header>
                  <Offcanvas.Body>
                    <Row className="mb-3">
                      <Col xl="12" sm="10" md="6" xl="4" className="m-auto">
                        <Form>
                          <Row className="mb-3">
                            <Form.Label htmlFor="blockCpc" className="col-sm-3 col-form-label">New Budget</Form.Label>
                            <Col sm="5">
                              <Form.Control type="number" id="blockCpc" placeholder="$40,000.00" />
                            </Col>
                            <Col sm="4">
                              <Row className="justify-content-end">
                                <Col xl="12"><Button type="submit" variant="primary"  className="float-end block">Save Budget</Button></Col>
                              </Row>
                            </Col>
                            <Col sm="12">
                              <Row className="justify-content-end">
                                <Col xl="12"><span className="d-block text-muted fs-11 ff-secondary lh-4 mt-4 text-center">Your spend this month: <b>$24,543.00</b></span></Col>
                                <span className="d-block text-muted fs-11 ff-secondary lh-4 text-center">Your next billing day: <b>1. Sep</b> (in 28 days)</span>
                              </Row>
                            </Col>
                          </Row>
                        </Form>
                      </Col>
                    </Row>
                  </Offcanvas.Body>
                </Offcanvas>
                <div className="table-responsive cursor-pointer" onClick={handleShow}>
                  <Grid
                    data={[
                      ['Top 5 Trade Finance Providers', 'Listicle', 'Trade Finance, US', 'TFG', '1,200', '#3', '$20.00', '$32.00'],
                      ['Top 10 Trade Finance Providers', 'Listicle', 'Trade Finance, US', 'TFG', '1,200', '#3', '$20.00', '$32.00'],
                      ['Top 10 Trade Finance Providers', 'Listicle', 'Trade Finance, US', 'TFG', '1,200', '#3', '$20.00', '$32.00'],
                      ['Top 10 Trade Finance Providers', 'Listicle', 'Trade Finance, US', 'TFG', '1,200', '#3', '$20.00', '$32.00'],
                      ['Top 5 Trade Finance Providers', 'Listicle', 'Trade Finance, US', 'TFG', '1,200', '#3', '$20.00', '$32.00'],
                      ['Top 10 Trade Finance Providers', 'Listicle', 'Trade Finance, US', 'TFG', '1,200', '#3', '$20.00', '$32.00']
                    ]}
                    columns={['Name', 'Block Type', 'Categories', 'Site', 'Views', 'Est. Position', 'Your Bid', 'Top Bid']}
                    search={true}
                    pagination={{
                      enabled: true,
                      limit: 15
                    }}
                    resizable={true}
                    sort={true}
                    className={{
                      table: 'table table-bordered table-hover mb-0'
                    }}
                    rowClick={(_, row) => {
                      window.location.href = `your-link-here/${row.cells[0].data}`; // replace 'your-link-here' with the base URL
                    }}
                  />
                </div>
              </Card.Body>
            </Card>
          </Col>
          <Col md="12">
            <Card className="card-one">
              <Card.Header>
                <Card.Title as="h6">Interval PPC Auctions</Card.Title>
                <Nav className="nav-icon nav-icon-sm ms-auto">
                  <Nav.Link href=""><i className="ri-refresh-line"></i></Nav.Link>
                  <Nav.Link href=""><i className="ri-more-2-fill"></i></Nav.Link>
                </Nav>
              </Card.Header>
              <Card.Body>
                <div className="table-responsive">
                  <Grid
                    data={[
                      ['Monthly Site Sponsor', 'Single Banner', 'Trade Finance, US', 'TFG', '1,200', '27 Days (31. Aug)', 'September', '#1', '$18,000.00', '$18,000.00'],
                      ['Best Finance Providers', 'Multi-Item Banner', 'Trade Finance, US', 'TFG', '1,200', '27 Days (31. Aug)', 'September', '#2', '$2,000.00', '$4,000.00'],
                      ['Our Banking Partners', 'Multi-Item Banner', 'Trade Finance, US', 'TFG', '1,200', '27 Days (31. Aug)', 'September', '#5', '$1,000.00', '$2,300.00']
                    ]}
                    columns={['Name', 'Block Type', 'Categories', 'Site', 'Views', 'Auction Close', 'Ad Period', 'Est. Position', 'Your Bid', 'Top Bid']}
                    search={true}
                    pagination={{
                      enabled: true,
                      limit: 15
                    }}
                    resizable={true}
                    sort={true}
                    className={{
                      table: 'table table-bordered table-hover mb-0'
                    }}
                  />
                </div>
              </Card.Body>
            </Card>
          </Col>

        </Row>

        <Footer />
      </div>
    </React.Fragment>
  )
}