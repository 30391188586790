import React from "react";
import { Link } from "react-router-dom";

import Img1 from "../assets/img/img10.jpg";
import Img2 from "../assets/img/img11.jpg";
import Img3 from "../assets/img/img14.jpg";
import Img4 from "../assets/img/img15.jpg";

// eslint-disable-next-line
export default Notification = [
  {
    "avatar": (<img src={Img1} alt=""/>),
    "text": (<React.Fragment><strong>ABC Bank</strong> would like to join <strong>Top 5 Trade Finance Providers</strong> for <strong>$23.50</strong></React.Fragment>),
    "date": "Aug 20 08:55am",
    "status": "online"
  },
  {
    "avatar": (<img src={Img2} alt="" />),
    "text": (<React.Fragment><strong>Santander</strong> would like to join <strong>Top 5 Trade Finance Providers</strong> for <strong>$20.00</strong></React.Fragment>),
    "date": "Aug 18 10:30am",
    "status": "online"
  },
  {
    "avatar": (<span className="avatar-initial bg-primary">d</span>),
    "text": (<React.Fragment><strong>Duval Inc</strong> would like to join <strong>Top 5 Trade Finance Providers</strong> for <strong>$18.00</strong></React.Fragment>),
    "date": "Aug 15 08:10pm",
    "status": ""
  }
];

