import React, { useEffect, useState } from "react";
import Header from "../../layouts/Header";
import Footer from "../../layouts/Footer";
import { Link } from "react-router-dom";
import { Button, Card, Col, Form, Badge, Carousel, Row } from "react-bootstrap";
import Select from "react-select";

import img1 from "../../assets/img/img1.jpg";
import img2 from "../../assets/img/img2.jpg";
import img3 from "../../assets/img/img3.jpg";

export default function PublisherInventoryCreate() {

  const currentSkin = (localStorage.getItem('skin-mode')) ? 'dark' : '';
  const [skin, setSkin] = useState(currentSkin);

  const switchSkin = (skin) => {
    if (skin === 'dark') {
      const btnWhite = document.getElementsByClassName('btn-white');

      for (const btn of btnWhite) {
        btn.classList.add('btn-outline-primary');
        btn.classList.remove('btn-white');
      }
    } else {
      const btnOutlinePrimary = document.getElementsByClassName('btn-outline-primary');

      for (const btn of btnOutlinePrimary) {
        btn.classList.remove('btn-outline-primary');
        btn.classList.add('btn-white');
      }
    }
  };

  const selectOptions = [
    { value: '1', label: 'ABC Bank' },
    { value: '2', label: 'Finance Inc.' },
    { value: '3', label: 'Duval Industries' }
  ];

  switchSkin(skin);

  useEffect(() => {
    switchSkin(skin);
  }, [skin]);

  return (
    <React.Fragment>
      <Header onSkin={setSkin} />
      <div className="main main-app p-3 p-lg-4">

        <div className="d-flex align-items-center justify-content-between">
          <div>
            <ol className="breadcrumb fs-sm mb-1">
              <li className="breadcrumb-item"><Link href="#">PUB Home</Link></li>
              <li className="breadcrumb-item active" aria-current="page">Create New Inventory</li>
            </ol>
            <h4 className="main-title mb-0">Create New Inventory</h4>
          </div>

        </div>

        <Row className="g-3 justify-content-center">
          <Col md="12">
            <Row>
              <Col sm="12" md="6">
                <Card className="card-settings mt-4">
                  <Card.Body className="p-0">
                    <div className="setting-item">
                      <Row className="g-2 align-items-center">
                        <Col md="3">
                          <h6>Block Title</h6>
                          <p>Required</p>
                        </Col>
                        <Col md>
                          <Form.Control type="text" placeholder="Enter Name" />
                        </Col>
                      </Row>
                    </div>
                    <div className="setting-item">
                      <Row className="g-2">
                        <Col md="3">
                          <h6>Subheading</h6>
                          <p>Optional</p>
                        </Col>
                        <Col md>
                          <Form.Control as="textarea" rows="2" placeholder="Enter Tagline" />
                        </Col>
                      </Row>
                    </div>
                    <div className="setting-item">
                      <Row className="g-2 align-items-center">
                        <Col md="3">
                          <h6># of Inventory Items</h6>
                          <p>Required</p>
                        </Col>
                        <Col md="1">
                          <Form.Control type="text" placeholder="#" />
                        </Col>
                      </Row>
                    </div>
                    <div className="setting-item">
                      <Row className="g-2 align-items-center">
                        <Col md="3">
                          <h6>Placeholders</h6>
                          <p>Required</p>
                        </Col>
                        <Col md>
                          <Select className="multi-select" options={selectOptions} placeholder="Select Products / Campaigns" isSearchable={true} isMulti />
                        </Col>
                      </Row>
                    </div>
                    <div className="setting-item">
                      <Row className="g-2 align-items-center">
                        <Col md="3">
                          <h6>Cover Image</h6>
                          <p>Optional</p>
                        </Col>
                        <Col md>
                          <Button variant="" className="btn-white">Upload Image</Button>
                        </Col>
                      </Row>
                    </div>
                    <div className="setting-item">
                      <Row className="g-2 align-items-center">
                        <Col md="3">
                          <h6>Bidding Model</h6>
                          <p>Required</p>
                        </Col>
                        <Col md>
                        <Form.Select aria-label="Default select example">
                          <option>Set Auction Model</option>
                          <option value="1">Live Auction</option>
                          <option value="2">Interval Auction</option>
                        </Form.Select>
                        </Col>
                      </Row>
                    </div>
                    <div className="setting-item">
                      <Row className="g-2 align-items-center">
                        <Col md="3">
                          <h6>Confirguration</h6>
                          <p>Optional</p>
                        </Col>
                        <Col md>
                          <Form.Check type="checkbox" label="Add in-depth campaign item overview block" className="mt-3" />
                          <Form.Check type="checkbox" label="Activate advertiser bidding" className="mt-1" />
                          <Form.Check type="checkbox" label="Create AI drafts for inventory applications" className="mt-1" />
                        </Col>
                      </Row>
                    </div>
                    <div className="setting-item">
                      <Row className="g-2 align-items-center">
                        <Col md="3">
                          <h6>Prompt for Tagline AI draft</h6>
                          <p>Optional</p>
                        </Col>
                        <Col md>
                          <Form.Control as="textarea" rows="4" placeholder="Write a one sentance summary in 10 words or less of {product_name} (website: {website}) in the context of {block_title}, not quoting the name of the product or using overly promotional terminology." />
                        </Col>
                      </Row>
                    </div>
                    <div className="setting-item">
                      <Row className="g-2 align-items-center">
                        <Col md="3">
                          <h6>Prompt for Product Overview AI draft</h6>
                          <p>Optional</p>
                        </Col>
                        <Col md>
                          <Form.Control as="textarea" rows="5" placeholder="Write a review of this product: {product_name} (website: {website}). Write approximately 300 words in an objective tone and return a response in HTML code, considering the text formatting, avoiding <h1>, <h2>, <h3>, <h4> or <h5> tags." />
                        </Col>
                      </Row>
                    </div>
                    <div className="setting-item">
                      <Row className="g-2 align-items-center">
                        <Col md="3">
                          <h6>Regenerate All Block Content</h6>
                          <p><i className="ri-error-warning-line"></i> Careful, this will reset all content</p>
                        </Col>
                        <Col md>
                        <Button variant="" className="btn-white">Regenerate Content</Button>
                        </Col>
                      </Row>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
              <Col sm="12" md="6">
                <Card className="card-embed">
                  <Card.Body className="p-3">
                    <Card className="card-settings">
                      <Card.Header>
                        <Card.Title>Top 5 Trade Finance Providers<Badge bg="primary" className="ms-2">Top List Preview</Badge></Card.Title>
                        <Card.Text>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed.</Card.Text>
                      </Card.Header>
                      <Card.Body className="p-0">
                        <div className="setting-item">
                          <Row className="g-2 align-items-center">
                            <Col md="1">
                              <h6>1.</h6>
                            </Col>
                            <Col md>
                              <div className="d-flex align-items-center">
                                <div className="flex-shrink-0">
                                  <img src={img1} shape="rounded" className="avatar avatar-rounded img-fluid" alt="" style={{ width: "80px", height: "auto"}} />
                                </div>
                                <div className="flex-grow-1 ms-3">
                                  <h6>ABC Bank<Badge bg="secondary" className="ms-2">Placeholder</Badge></h6>
                                  <p>ABC provides full-service loans and trade-finance facilities</p>
                                  <Button variant="outline-primary mt-2" size="sm">Edit Record</Button>
                                </div>
                              </div>
                            </Col>
                          </Row>
                        </div>
                        <div className="setting-item">
                          <Row className="g-2 align-items-center">
                            <Col md="1">
                              <h6>2.</h6>
                            </Col>
                            <Col md>
                              <div className="d-flex align-items-center">
                                <div className="flex-shrink-0">
                                  <img src={img1} shape="rounded" className="avatar avatar-rounded img-fluid" alt="" style={{ width: "80px", height: "auto"}} />
                                </div>
                                <div className="flex-grow-1 ms-3">
                                  <h6>ABC Bank<Badge bg="secondary" className="ms-2">Placeholder</Badge></h6>
                                  <p>ABC provides full-service loans and trade-finance facilities</p>
                                  <Button variant="outline-primary mt-2" size="sm">Edit Record</Button>
                                </div>
                              </div>
                            </Col>
                          </Row>
                        </div>
                        <div className="setting-item">
                          <Row className="g-2 align-items-center">
                            <Col md="1">
                              <h6>3.</h6>
                            </Col>
                            <Col md>
                              <div className="d-flex align-items-center">
                                <div className="flex-shrink-0">
                                  <img src={img1} shape="rounded" className="avatar avatar-rounded img-fluid" alt="" style={{ width: "80px", height: "auto"}} />
                                </div>
                                <div className="flex-grow-1 ms-3">
                                  <h6>ABC Bank<Badge bg="secondary" className="ms-2">Placeholder</Badge></h6>
                                  <p>ABC provides full-service loans and trade-finance facilities</p>
                                  <Button variant="outline-primary mt-2" size="sm">Edit Record</Button>
                                </div>
                              </div>
                            </Col>
                          </Row>
                        </div>
                        <div className="setting-item">
                          <Row className="g-2 align-items-center">
                            <Col md="1">
                              <h6>4.</h6>
                            </Col>
                            <Col md>
                              <div className="d-flex align-items-center">
                                <div className="flex-shrink-0">
                                  <img src={img1} shape="rounded" className="avatar avatar-rounded img-fluid" alt="" style={{ width: "80px", height: "auto"}} />
                                </div>
                                <div className="flex-grow-1 ms-3">
                                  <h6>ABC Bank<Badge bg="secondary" className="ms-2">Placeholder</Badge></h6>
                                  <p>ABC provides full-service loans and trade-finance facilities</p>
                                  <Button variant="outline-primary mt-2" size="sm">Edit Record</Button>
                                </div>
                              </div>
                            </Col>
                          </Row>
                        </div>
                        <div className="setting-item">
                          <Row className="g-2 align-items-center">
                            <Col md="1">
                              <h6>5.</h6>
                            </Col>
                            <Col md>
                              <div className="d-flex align-items-center">
                                <div className="flex-shrink-0 me-1">
                                  <img src={img1} shape="rounded" className="avatar avatar-rounded img-fluid" alt="" style={{ width: "80px", height: "auto"}} />
                                </div>
                                <div className="flex-grow-1 ms-3">
                                  <h6>ABC Bank<Badge bg="secondary" className="ms-2">Placeholder</Badge></h6>
                                  <p>ABC provides full-service loans and trade-finance facilities</p>
                                  <Button variant="outline-primary mt-2" size="sm">Edit Record</Button>
                                </div>
                              </div>
                            </Col>
                          </Row>
                        </div>
                      </Card.Body>
                    </Card>
                  </Card.Body>
                  <Card.Footer>
                  <pre class="language-html" tabindex="0"><code class="language-html"><span class="token tag"><span class="token tag"><span class="token punctuation">[</span>revbox</span><span class="token attr-name"> type</span><span class="token attr-value"><span class="token punctuation attr-equals">=</span>"top-list"</span><span class="token attr-name"> inv</span><span class="token attr-value"><span class="token punctuation attr-equals">=</span>"1"</span><span class="token punctuation">]</span></span></code></pre>
                  </Card.Footer>
                </Card>
                <Card className="card-embed mt-4">
                  <Card.Body className="p-3">
                    <Card className="card-settings">
                      <Card.Header>
                        <Card.Title>Top 5 Trade Finance Providers<Badge bg="primary" className="ms-2">Product Overview Preview</Badge></Card.Title>
                        <Card.Text>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed.</Card.Text>
                      </Card.Header>
                      <Card.Body className="p-0">
                        <div className="setting-item">
                          <Row className="g-2 align-items-center">
                            <Col md="1">
                              <h6>1.</h6>
                            </Col>
                            <Col md>
                              <div className="d-flex align-items-center">
                                <div className="flex-shrink-0 me-1">
                                  <img src={img1} shape="rounded" className="avatar avatar-rounded img-fluid" alt="" style={{ width: "90px", height: "auto"}} />
                                </div>
                                <div className="flex-grow-1 ms-3">
                                  <h6>ABC Bank<Badge bg="secondary" className="ms-2">Placeholder</Badge></h6>
                                  <p>ABC provides full-service loans and trade-finance facilities</p>
                                  <Button variant="outline-primary mt-2" size="sm">Edit Record</Button>
                                </div>
                              </div>
                              <div className="mt-3" style={{ maxWidth: "400px"}}>
                                <Carousel>
                                  <Carousel.Item>
                                    <img
                                      className="d-block w-100"
                                      src={img2}
                                      alt="First Screenshot"
                                    />
                                    <Carousel.Caption>
                                      <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p>
                                    </Carousel.Caption>
                                  </Carousel.Item>
                                  <Carousel.Item>
                                    <img
                                      className="d-block w-100"
                                      src={img3}
                                      alt="Second Screenshot"
                                    />
                                    <Carousel.Caption>
                                      <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                                    </Carousel.Caption>
                                  </Carousel.Item>
                                </Carousel>
                              </div>
                              <div className="mt-3">
                                <p className="mt-2">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed vitae semper nisl, sit amet tristique magna. Proin sed malesuada risus. Donec quis facilisis nulla, a interdum sem. Donec condimentum, est id tempus aliquet, sem nisi pharetra orci, ut mollis lectus diam in nulla. Curabitur at tellus vitae velit mollis pharetra.</p>
                                <p className="mt-2">Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Vivamus non sem lacus. Sed in aliquet tellus. In hac habitasse platea dictumst. Nulla facilisi. Donec in metus sit amet risus feugiat lacinia non quis ante. Donec non enim pulvinar, lacinia nunc id, fringilla dolor.</p>
                                <p className="mt-2">Fusce aliquet, mi at varius aliquam, arcu lorem luctus sem, eget vehicula justo orci sit amet nunc. Proin at risus porta, semper mi nec, aliquet mauris. Nullam ac diam non lorem malesuada luctus. Sed vehicula, turpis eu pellentesque tincidunt, metus ex cursus magna, non posuere erat eros eget purus.</p>
                              </div>
                            </Col>
                          </Row>
                        </div>
                        <div className="setting-item">
                          <Row className="g-2 align-items-center">
                            <Col md="1">
                              <h6>2.</h6>
                            </Col>
                            <Col md>
                              <div className="d-flex align-items-center">
                                <div className="flex-shrink-0 me-1">
                                  <img src={img1} shape="rounded" className="avatar avatar-rounded img-fluid" alt="" style={{ width: "90px", height: "auto"}} />
                                </div>
                                <div className="flex-grow-1 ms-3">
                                  <h6>ABC Bank<Badge bg="secondary" className="ms-2">Placeholder</Badge></h6>
                                  <p>ABC provides full-service loans and trade-finance facilities</p>
                                  <Button variant="outline-primary mt-2" size="sm">Edit Record</Button>
                                </div>
                              </div>
                              <div className="mt-3" style={{ maxWidth: "400px"}}>
                                <Carousel>
                                  <Carousel.Item>
                                    <img
                                      className="d-block w-100"
                                      src={img2}
                                      alt="First Screenshot"
                                    />
                                    <Carousel.Caption>
                                      <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p>
                                    </Carousel.Caption>
                                  </Carousel.Item>
                                  <Carousel.Item>
                                    <img
                                      className="d-block w-100"
                                      src={img3}
                                      alt="Second Screenshot"
                                    />
                                    <Carousel.Caption>
                                      <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                                    </Carousel.Caption>
                                  </Carousel.Item>
                                </Carousel>
                              </div>
                              <div className="mt-3">
                                <p className="mt-2">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed vitae semper nisl, sit amet tristique magna. Proin sed malesuada risus. Donec quis facilisis nulla, a interdum sem. Donec condimentum, est id tempus aliquet, sem nisi pharetra orci, ut mollis lectus diam in nulla. Curabitur at tellus vitae velit mollis pharetra.</p>
                                <p className="mt-2">Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Vivamus non sem lacus. Sed in aliquet tellus. In hac habitasse platea dictumst. Nulla facilisi. Donec in metus sit amet risus feugiat lacinia non quis ante. Donec non enim pulvinar, lacinia nunc id, fringilla dolor.</p>
                                <p className="mt-2">Fusce aliquet, mi at varius aliquam, arcu lorem luctus sem, eget vehicula justo orci sit amet nunc. Proin at risus porta, semper mi nec, aliquet mauris. Nullam ac diam non lorem malesuada luctus. Sed vehicula, turpis eu pellentesque tincidunt, metus ex cursus magna, non posuere erat eros eget purus.</p>
                              </div>
                            </Col>
                          </Row>
                        </div>
                        <div className="setting-item">
                          <Row className="g-2 align-items-center">
                            <Col md="1">
                              <h6>3.</h6>
                            </Col>
                            <Col md>
                              <div className="d-flex align-items-center">
                                <div className="flex-shrink-0 me-1">
                                  <img src={img1} shape="rounded" className="avatar avatar-rounded img-fluid" alt="" style={{ width: "90px", height: "auto"}} />
                                </div>
                                <div className="flex-grow-1 ms-3">
                                  <h6>ABC Bank<Badge bg="secondary" className="ms-2">Placeholder</Badge></h6>
                                  <p>ABC provides full-service loans and trade-finance facilities</p>
                                  <Button variant="outline-primary mt-2" size="sm">Edit Record</Button>
                                </div>
                              </div>
                              <div className="mt-3" style={{ maxWidth: "400px"}}>
                                <Carousel>
                                  <Carousel.Item>
                                    <img
                                      className="d-block w-100"
                                      src={img2}
                                      alt="First Screenshot"
                                    />
                                    <Carousel.Caption>
                                      <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p>
                                    </Carousel.Caption>
                                  </Carousel.Item>
                                  <Carousel.Item>
                                    <img
                                      className="d-block w-100"
                                      src={img3}
                                      alt="Second Screenshot"
                                    />
                                    <Carousel.Caption>
                                      <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                                    </Carousel.Caption>
                                  </Carousel.Item>
                                </Carousel>
                              </div>
                              <div className="mt-3">
                                <p className="mt-2">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed vitae semper nisl, sit amet tristique magna. Proin sed malesuada risus. Donec quis facilisis nulla, a interdum sem. Donec condimentum, est id tempus aliquet, sem nisi pharetra orci, ut mollis lectus diam in nulla. Curabitur at tellus vitae velit mollis pharetra.</p>
                                <p className="mt-2">Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Vivamus non sem lacus. Sed in aliquet tellus. In hac habitasse platea dictumst. Nulla facilisi. Donec in metus sit amet risus feugiat lacinia non quis ante. Donec non enim pulvinar, lacinia nunc id, fringilla dolor.</p>
                                <p className="mt-2">Fusce aliquet, mi at varius aliquam, arcu lorem luctus sem, eget vehicula justo orci sit amet nunc. Proin at risus porta, semper mi nec, aliquet mauris. Nullam ac diam non lorem malesuada luctus. Sed vehicula, turpis eu pellentesque tincidunt, metus ex cursus magna, non posuere erat eros eget purus.</p>
                              </div>
                            </Col>
                          </Row>
                        </div>
                        <div className="setting-item">
                          <Row className="g-2 align-items-center">
                            <Col md="1">
                              <h6>4.</h6>
                            </Col>
                            <Col md>
                              <div className="d-flex align-items-center">
                                <div className="flex-shrink-0">
                                  <img src={img1} shape="rounded" className="avatar avatar-rounded img-fluid" alt="" style={{ width: "90px", height: "auto"}} />
                                </div>
                                <div className="flex-grow-1 ms-3">
                                  <h6>ABC Bank<Badge bg="secondary" className="ms-2">Placeholder</Badge></h6>
                                  <p>ABC provides full-service loans and trade-finance facilities</p>
                                  <Button variant="outline-primary mt-2" size="sm">Edit Record</Button>
                                </div>
                              </div>
                              <div className="mt-3" style={{ maxWidth: "400px"}}>
                                <Carousel>
                                  <Carousel.Item>
                                    <img
                                      className="d-block w-100"
                                      src={img2}
                                      alt="First Screenshot"
                                    />
                                    <Carousel.Caption>
                                      <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p>
                                    </Carousel.Caption>
                                  </Carousel.Item>
                                  <Carousel.Item>
                                    <img
                                      className="d-block w-100"
                                      src={img3}
                                      alt="Second Screenshot"
                                    />
                                    <Carousel.Caption>
                                      <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                                    </Carousel.Caption>
                                  </Carousel.Item>
                                </Carousel>
                              </div>
                              <div className="mt-3">
                                <p className="mt-2">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed vitae semper nisl, sit amet tristique magna. Proin sed malesuada risus. Donec quis facilisis nulla, a interdum sem. Donec condimentum, est id tempus aliquet, sem nisi pharetra orci, ut mollis lectus diam in nulla. Curabitur at tellus vitae velit mollis pharetra.</p>
                                <p className="mt-2">Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Vivamus non sem lacus. Sed in aliquet tellus. In hac habitasse platea dictumst. Nulla facilisi. Donec in metus sit amet risus feugiat lacinia non quis ante. Donec non enim pulvinar, lacinia nunc id, fringilla dolor.</p>
                                <p className="mt-2">Fusce aliquet, mi at varius aliquam, arcu lorem luctus sem, eget vehicula justo orci sit amet nunc. Proin at risus porta, semper mi nec, aliquet mauris. Nullam ac diam non lorem malesuada luctus. Sed vehicula, turpis eu pellentesque tincidunt, metus ex cursus magna, non posuere erat eros eget purus.</p>
                              </div>
                            </Col>
                          </Row>
                        </div>
                        <div className="setting-item">
                          <Row className="g-2 align-items-center">
                            <Col md="1">
                              <h6>5.</h6>
                            </Col>
                            <Col md>
                              <div className="d-flex align-items-center">
                                <div className="flex-shrink-0">
                                  <img src={img1} shape="rounded" className="avatar avatar-rounded img-fluid" alt="" style={{ width: "90px", height: "auto"}} />
                                </div>
                                <div className="flex-grow-1 ms-3">
                                  <h6>ABC Bank<Badge bg="secondary" className="ms-2">Placeholder</Badge></h6>
                                  <p>ABC provides full-service loans and trade-finance facilities</p>
                                  <Button variant="outline-primary mt-2" size="sm">Edit Record</Button>
                                </div>
                              </div>
                              <div className="mt-3" style={{ maxWidth: "400px"}}>
                                <Carousel>
                                  <Carousel.Item>
                                    <img
                                      className="d-block w-100"
                                      src={img2}
                                      alt="First Screenshot"
                                    />
                                    <Carousel.Caption>
                                      <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p>
                                    </Carousel.Caption>
                                  </Carousel.Item>
                                  <Carousel.Item>
                                    <img
                                      className="d-block w-100"
                                      src={img3}
                                      alt="Second Screenshot"
                                    />
                                    <Carousel.Caption>
                                      <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                                    </Carousel.Caption>
                                  </Carousel.Item>
                                </Carousel>
                              </div>
                              <div className="mt-3">
                                <p className="mt-2">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed vitae semper nisl, sit amet tristique magna. Proin sed malesuada risus. Donec quis facilisis nulla, a interdum sem. Donec condimentum, est id tempus aliquet, sem nisi pharetra orci, ut mollis lectus diam in nulla. Curabitur at tellus vitae velit mollis pharetra.</p>
                                <p className="mt-2">Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Vivamus non sem lacus. Sed in aliquet tellus. In hac habitasse platea dictumst. Nulla facilisi. Donec in metus sit amet risus feugiat lacinia non quis ante. Donec non enim pulvinar, lacinia nunc id, fringilla dolor.</p>
                                <p className="mt-2">Fusce aliquet, mi at varius aliquam, arcu lorem luctus sem, eget vehicula justo orci sit amet nunc. Proin at risus porta, semper mi nec, aliquet mauris. Nullam ac diam non lorem malesuada luctus. Sed vehicula, turpis eu pellentesque tincidunt, metus ex cursus magna, non posuere erat eros eget purus.</p>
                              </div>
                            </Col>
                          </Row>
                        </div>
                      </Card.Body>
                    </Card>
                  </Card.Body>
                  <Card.Footer>
                  <pre class="language-html" tabindex="0"><code class="language-html"><span class="token tag"><span class="token tag"><span class="token punctuation">[</span>revbox</span><span class="token attr-name"> type</span><span class="token attr-value"><span class="token punctuation attr-equals">=</span>"top-block"</span><span class="token attr-name"> inv</span><span class="token attr-value"><span class="token punctuation attr-equals">=</span>"1"</span><span class="token punctuation">]</span></span></code></pre>
                  </Card.Footer>
                </Card>
              </Col>
            </Row>
          </Col>

        </Row>

        <Footer />
      </div>
    </React.Fragment>
  )
}