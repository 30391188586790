import React, { useEffect, useState } from "react";
import Header from "../layouts/Header";
import Footer from "../layouts/Footer";
import { Link } from "react-router-dom";
import { Col, Row, Form, Nav, Card, Modal, Button, Badge, Table } from "react-bootstrap";
import HeaderMobile from "../layouts/HeaderMobile";
import Avatar from "../components/Avatar";

import img8 from "../assets/img/img8.jpg";
import img9 from "../assets/img/img9.jpg";
import img10 from "../assets/img/img10.jpg";
import img11 from "../assets/img/img11.jpg";
import img14 from "../assets/img/img14.jpg";

export default function AdvertiserSettings() {
  const currentSkin = (localStorage.getItem('skin-mode')) ? 'dark' : '';
  const [skin, setSkin] = useState(currentSkin);

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const switchSkin = (skin) => {
    if (skin === 'dark') {
      const btnWhite = document.getElementsByClassName('btn-white');

      for (const btn of btnWhite) {
        btn.classList.add('btn-outline-primary');
        btn.classList.remove('btn-white');
      }
    } else {
      const btnOutlinePrimary = document.getElementsByClassName('btn-outline-primary');

      for (const btn of btnOutlinePrimary) {
        btn.classList.remove('btn-outline-primary');
        btn.classList.add('btn-white');
      }
    }
  };

  switchSkin(skin);

  useEffect(() => {
    switchSkin(skin);
  }, [skin]);
  return (
    <React.Fragment>
      <Header onSkin={setSkin} />
      <div className="main main-app p-3 p-lg-4">
        <ol className="breadcrumb fs-sm mb-2">
          <li className="breadcrumb-item"><Link to="#">Advertisers</Link></li>
          <li className="breadcrumb-item active" aria-current="page">Settings</li>
        </ol>
        <h2 className="main-title">Settings</h2>

        <Nav className="nav-line mb-4">
          <Nav.Link href="#user">User Account</Nav.Link>
          <Nav.Link href="#account">Account Access</Nav.Link>
          <Nav.Link href="#billing">Billing</Nav.Link>
          <Nav.Link href="#notifications">Notifications</Nav.Link>
        </Nav>

        <Card id="user" className="card-settings">
          <Card.Header>
            <Card.Title className="mb-0">User Account<Badge bg="primary" className="ms-2">Admin</Badge></Card.Title>
          </Card.Header>
          <Card.Body className="p-0">
            <div className="setting-item">
              <Row className="g-2 align-items-center">
                <Col md="5">
                  <h6>First Name</h6>
                  <p>Neque porro quisquam est qui dolorem.</p>
                </Col>
                <Col md>
                  <Form.Control type="text" placeholder="First name" />
                </Col>
              </Row>
            </div>
            <div className="setting-item">
              <Row className="g-2 align-items-center">
                <Col md="5">
                  <h6>Last Name</h6>
                  <p>Neque porro quisquam est qui dolorem.</p>
                </Col>
                <Col md>
                  <Form.Control type="text" placeholder="Last name" />
                </Col>
              </Row>
            </div>
            <div className="setting-item">
              <Row className="g-2 align-items-center">
                <Col md="5">
                  <h6>Email</h6>
                  <p>Neque porro quisquam est qui dolorem.</p>
                </Col>
                <Col md>
                  <Form.Control type="text" placeholder="Enter email address" />
                  <Form.Check type="checkbox" label="Blanditiis praesentium voluptatum deleniti atque." className="mt-3" />
                  <Form.Check type="checkbox" label="Similique sunt in culpa qui officia." className="mt-1" />
                </Col>
              </Row>
            </div>
            <div className="setting-item">
              <Row className="g-2 align-items-center">
                <Col md="5">
                  <h6>Password</h6>
                  <p>Neque porro quisquam est qui dolorem.</p>
                </Col>
                <Col md>
                  <Form.Control type="text" placeholder="***********" />
                </Col>
              </Row>
            </div>
          </Card.Body>
        </Card>

        <Card id="account" className="card-settings mt-3">
          <Card.Header>
            <div className="d-flex justify-content-between">
              <div className=""><Card.Title className="mb-0">Account Access</Card.Title></div>
              <div className="">
                <Button variant="outline-primary" onClick={handleShow} size="sm">
                  Add New User
                </Button>
                <Modal show={show} onHide={handleClose} centered>
                  <Modal.Header closeButton>
                    <Modal.Title>Add New User</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <Row className="g-2">
                      <Col>
                        <Form.Label htmlFor="firstName">First name</Form.Label>
                        <Form.Control id="firstName" type="text" placeholder="First name" aria-label="First name" />
                      </Col>
                      <Col>
                        <Form.Label htmlFor="lastName">Last name</Form.Label>
                        <Form.Control id="lastName" type="text" placeholder="Last name" aria-label="Last name" />
                      </Col>
                    </Row>
                    <Row className="mt-2 g-2">
                      <Col>
                        <Form.Label htmlFor="email">Email</Form.Label>
                        <Form.Control id="email" type="email" placeholder="Email" aria-label="Email" />
                      </Col>
                      <Col>
                        <Form.Label htmlFor="permission">Permission</Form.Label>
                        <Form.Select id="permission" aria-label="Default select example">
                          <option value="1">Admin</option>
                        </Form.Select>
                      </Col>
                    </Row>
                  </Modal.Body>
                  <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                      Discard
                    </Button>
                    <Button variant="primary" onClick={handleClose}>
                      Invite User
                    </Button>
                  </Modal.Footer>
                </Modal>
            </div>
            </div>
          </Card.Header>
          <Card.Body className="p-0">
            <div className="setting-item">
              <Row className="g-2">
                <Col md="5">
                  <h6>Account Users</h6>
                  <p>Neque porro quisquam est qui dolorem.</p>
                </Col>
                <Col md>
                  <Table className="mb-0" responsive>
                    <thead>
                      <tr>
                        <th scope="col">Name</th>
                        <th scope="col">Last Active</th>
                        <th scope="col">&nbsp;</th>
                      </tr>
                    </thead>
                    <tbody>
                      {[
                        {
                          "img": img14,
                          "name": "Adrian Moniño",
                          "date": "May 20, 2023"
                        }, {
                          "img": img10,
                          "name": "Socrates Itumay",
                          "date": "Apr 10, 2023"
                        }, {
                          "img": img8,
                          "name": "Rhea Castañares",
                          "date": "Feb 16, 2023"
                        }, {
                          "img": img9,
                          "name": "Marianne Audrey",
                          "date": "Feb 10, 2023"
                        }, {
                          "img": img11,
                          "name": "Reynante Labares",
                          "date": "Dec 20, 2022"
                        }
                      ].map((user, index) => (
                        <tr key={index}>
                          <td>
                            <div className="d-flex align-items-center gap-2">
                              <Avatar size="sm" img={user.img} />
                              <span>{user.name}</span>
                            </div>
                          </td>
                          <td>{user.date}</td>
                          <td>
                            <Nav as="nav">
                              <Link to=""><i className="ri-pencil-line"></i></Link>
                              <Link to=""><i className="ri-delete-bin-line"></i></Link>
                            </Nav>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </Col>
              </Row>
            </div>
          </Card.Body>
        </Card>

        <Row id="billing" className="g-2 align-items-center">
          <Col md="12" xl="8">
            <Card className="card-settings mt-3">
              <Card.Header>
                <Card.Title className="mb-0">Billing</Card.Title>
              </Card.Header>
              <Card.Body className="p-0">
                <div className="setting-item">
                  <Row className="g-2 align-items-center">
                    <Col md="5">
                      <h6>First Name</h6>
                      <p>Neque porro quisquam est qui dolorem.</p>
                    </Col>
                    <Col md>
                      <Form.Control type="text" placeholder="e.g. John" />
                    </Col>
                  </Row>
                </div>
                <div className="setting-item">
                  <Row className="g-2 align-items-center">
                    <Col md="5">
                      <h6>Last Name</h6>
                      <p>Neque porro quisquam est qui dolorem.</p>
                    </Col>
                    <Col md>
                      <Form.Control type="text" placeholder="e.g. Doe" />
                    </Col>
                  </Row>
                </div>
                <div className="setting-item">
                  <Row className="g-2 align-items-center">
                    <Col md="5">
                      <h6>Company Name</h6>
                      <p>Neque porro quisquam est qui dolorem.</p>
                    </Col>
                    <Col md>
                      <Form.Control type="text" placeholder="e.g. Revbox" />
                    </Col>
                  </Row>
                </div>
                <div className="setting-item">
                  <Row className="g-2 align-items-center">
                    <Col md="5">
                      <h6>Billing Address</h6>
                      <p>Neque porro quisquam est qui dolorem.</p>
                    </Col>
                    <Col md>
                      <Form.Control className="mb-2" type="text" placeholder="Line 1 - required" />
                      <Form.Control className="mb-2" type="text" placeholder="Line 2 - optional" />
                      <Row className="g-2 align-items-center">
                        <Col md="6">
                          <Form.Control type="text" placeholder="Post Code" />
                        </Col>
                        <Col md="6">
                          <Form.Control type="text" placeholder="Town" />
                        </Col>
                        <Col md="6">
                          <Form.Control type="text" placeholder="Country" />
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </div>
                <div className="setting-item">
                  <Row className="g-2 align-items-center">
                    <Col md="5">
                      <h6>VAT Number</h6>
                      <p>Optional</p>
                    </Col>
                    <Col md>
                      <Form.Control type="text" placeholder="e.g. GB123456789" />
                    </Col>
                  </Row>
                </div>
                <div className="setting-item">
                  <Row className="g-2 align-items-center">
                    <Col md="5">
                      <h6>Billing CC Email</h6>
                      <p>Invoices will be sent to all admin users and this CC email address.</p>
                    </Col>
                    <Col md>
                      <Form.Control type="text" placeholder="Enter email address" />
                    </Col>
                  </Row>
                </div>
              </Card.Body>
            </Card>            
          </Col>
          <Col sm="12" xl="4">
            <Card id="" className="card-one card-wallet" style={{ height: "250px"}}>
              <Card.Body>
                <div className="finance-icon">
                  <i className="ri-mastercard-fill"></i>
                  <i className="ri-visa-line"></i>
                </div>
                <label className="card-title mb-1">Company Name</label>
                <h2 className="card-value mb-auto">Duval Inc.</h2>

                <label className="card-title mb-1">Card Number</label>
                <div className="d-flex align-items-center gap-4 mb-3">
                  <div className="d-flex gap-1">
                    <span className="badge-dot"></span>
                    <span className="badge-dot"></span>
                    <span className="badge-dot"></span>
                    <span className="badge-dot"></span>
                  </div>
                  <div className="d-flex gap-1">
                    <span className="badge-dot"></span>
                    <span className="badge-dot"></span>
                    <span className="badge-dot"></span>
                    <span className="badge-dot"></span>
                  </div>
                  <div className="d-flex gap-1">
                    <span className="badge-dot"></span>
                    <span className="badge-dot"></span>
                    <span className="badge-dot"></span>
                    <span className="badge-dot"></span>
                  </div>
                  <h5 className="ff-numerals mb-0">5314</h5>
                </div>

                <label className="card-title mb-1">Card Holder</label>
                <h5 className="mb-0">Richard M. Christensen</h5>
              </Card.Body>
            </Card>
          </Col>
        </Row>

        <Card id="notifications" className="card-settings mt-3">
          <Card.Header>
            <Card.Title className="mb-0">Notifications</Card.Title>
          </Card.Header>
          <Card.Body className="">
            <Table className="table-pricing" responsive>
              <thead>
                <tr>
                  <th className="w-32"></th>
                  <th className="w-17 p-0">
                    <span className="mb-0">Notify me via</span>
                    <div className="pricing-price">Email</div>
                  </th>
                  <th className="w-17 p-0">
                    <span className="mb-0">Notify me via</span>
                    <div className="pricing-price">Push</div>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr className="title">
                  <td>General</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                </tr>
                <tr>
                  <td>
                    <p className="mb-1">Updates from Publishers</p>
                    <small>Occasional messages from publishers with updates or offers</small>
                  </td>
                  <td><div className="inline-block"><div className="inline-block badge bg-success"><Form.Check type="switch" className="inline-block" /> ON</div></div></td>
                  <td><div className="inline-block"><div className="inline-block badge bg-dark"><Form.Check type="switch" disabled className="inline-block" /> N/A</div></div></td>
                </tr>
                <tr>
                  <td>
                    <p className="mb-1">Product updates from Revbox</p>
                    <small>Occasional messages from Revbox with product updates (rare)</small>
                  </td>
                  <td><div className="inline-block"><div className="inline-block badge bg-success"><Form.Check type="switch" className="inline-block" /> ON</div></div></td>
                  <td><div className="inline-block"><div className="inline-block badge bg-dark"><Form.Check type="switch" disabled className="inline-block" /> N/A</div></div></td>
                </tr>
                <tr className="title">
                  <td>Transactional</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                </tr>
                <tr>
                  <td>
                    <p className="mb-1">Invoice & Transaction Receipts</p>
                    <small>We'll always notify you of charges or new invoices</small>
                  </td>
                  <td><div className="inline-block"><div className="inline-block badge bg-success"><Form.Check type="switch" checked disabled className="inline-block" /> ON</div></div></td>
                  <td><div className="inline-block"><div className="inline-block badge bg-dark"><Form.Check type="switch" disabled className="inline-block" /> N/A</div></div></td>
                </tr>
                <tr>
                  <td>
                    <p className="mb-1">System or Campaign Changes</p>
                    <small>We'll always notify you of important system or campaign changes</small>
                  </td>
                  <td><div className="inline-block"><div className="inline-block badge bg-success"><Form.Check type="switch" checked disabled className="inline-block" /> ON</div></div></td>
                  <td><div className="inline-block"><div className="inline-block badge bg-dark"><Form.Check type="switch" disabled className="inline-block" /> N/A</div></div></td>
                </tr>
                <tr className="title">
                  <td>PPC Auctions</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                </tr>
                <tr>
                  <td>
                    <p className="mb-1">Outbid Notifications</p>
                    <small>We'll send you a daily roundup email if there were position drops</small>
                  </td>
                  <td><div className="inline-block"><div className="inline-block badge bg-success"><Form.Check type="switch" className="inline-block" /> ON</div></div></td>
                  <td><div className="inline-block"><div className="inline-block badge bg-dark"><Form.Check type="switch" disabled className="inline-block" /> N/A</div></div></td>
                </tr>
                <tr>
                  <td>
                    <p className="mb-1">Budget Warnings</p>
                    <small>We'll send you a heads-up when you reach 75% of your budget and are likely to run out</small>
                  </td>
                  <td><div className="inline-block"><div className="inline-block badge bg-success"><Form.Check type="switch" className="inline-block" /> ON</div></div></td>
                  <td><div className="inline-block"><div className="inline-block badge bg-dark"><Form.Check type="switch" disabled className="inline-block" /> N/A</div></div></td>
                </tr>
                <tr>
                  <td>
                    <p className="mb-1">Budget Exhaution</p>
                    <small>We'll notify you when your budget runs out and your campaign pauses</small>
                  </td>
                  <td><div className="inline-block"><div className="inline-block badge bg-success"><Form.Check type="switch" className="inline-block" /> ON</div></div></td>
                  <td><div className="inline-block"><div className="inline-block badge bg-dark"><Form.Check type="switch" disabled className="inline-block" /> N/A</div></div></td>
                </tr>
                <tr>
                  <td>
                    <p className="mb-1">Monthly Performance Roundups</p>
                    <small>Send me a monthly overview of my campaign performance</small>
                  </td>
                  <td><div className="inline-block"><div className="inline-block badge bg-success"><Form.Check type="switch" className="inline-block" /> ON</div></div></td>
                  <td><div className="inline-block"><div className="inline-block badge bg-dark"><Form.Check type="switch" disabled className="inline-block" /> N/A</div></div></td>
                </tr>
                <tr>
                  <td>
                    <p className="mb-1">New Inventory Notifications</p>
                    <small>Let me know when new relevant inventory becomes available</small>
                  </td>
                  <td><div className="inline-block"><div className="inline-block badge bg-success"><Form.Check type="switch" className="inline-block" /> ON</div></div></td>
                  <td><div className="inline-block"><div className="inline-block badge bg-dark"><Form.Check type="switch" disabled className="inline-block" /> N/A</div></div></td>
                </tr>
                <tr className="title">
                  <td>Lead Auctions</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                </tr>
                <tr>
                  <td>
                    <p className="mb-1">Outbid Notifications</p>
                    <small>We'll send you a daily roundup email if there were position drops</small>
                  </td>
                  <td><div className="inline-block"><div className="inline-block badge bg-success"><Form.Check type="switch" className="inline-block" /> ON</div></div></td>
                  <td><div className="inline-block"><div className="inline-block badge bg-dark"><Form.Check type="switch" disabled className="inline-block" /> N/A</div></div></td>
                </tr>
                <tr>
                  <td>
                    <p className="mb-1">Budget Warnings</p>
                    <small>We'll send you a heads-up when you reach 75% of your budget and are likely to run out</small>
                  </td>
                  <td><div className="inline-block"><div className="inline-block badge bg-success"><Form.Check type="switch" className="inline-block" /> ON</div></div></td>
                  <td><div className="inline-block"><div className="inline-block badge bg-dark"><Form.Check type="switch" disabled className="inline-block" /> N/A</div></div></td>
                </tr>
                <tr>
                  <td>
                    <p className="mb-1">Budget Exhaution</p>
                    <small>We'll notify you when your budget runs out and your campaign pauses</small>
                  </td>
                  <td><div className="inline-block"><div className="inline-block badge bg-success"><Form.Check type="switch" className="inline-block" /> ON</div></div></td>
                  <td><div className="inline-block"><div className="inline-block badge bg-dark"><Form.Check type="switch" disabled className="inline-block" /> N/A</div></div></td>
                </tr>
              </tbody>
            </Table>
          </Card.Body>
        </Card>

        <Footer />
      </div>
    </React.Fragment>
  );
}
