const aug23 = [
  [1, 34252.28], // Tuesday
  [2, 33515.23], // Wednesday
  [3, 31279.84], // Thursday
  [4, 29046.56], // Friday
  [5, 18371.73], // Saturday
  [6, 20050.83], // Sunday
  [7, 31064.89], // Monday
  [8, 33062.54], // Tuesday
  [9, 31915.96], // Wednesday
  [10, 30545.47], // Thursday
  [11, 27466.23], // Friday
  [12, 17853.32], // Saturday
  [13, 19561.28], // Sunday
  [14, 29585.92], // Monday
  [15, 32714.56], // Tuesday
  [16, 30973.95], // Wednesday
  [17, 29179.43], // Thursday
  [18, 27384.76], // Friday
  [19, 17586.33], // Saturday
  [20, 18933.54], // Sunday
  [21, 29746.78], // Monday
  [22, 32432.16], // Tuesday
  [23, 31087.12], // Wednesday
  [24, 28664.56], // Thursday
  [25, 25912.83], // Friday
  [26, 17284.73], // Saturday
  [27, 18706.81], // Sunday
  [28, 29234.25], // Monday
  [29, 31074.63], // Tuesday
  [30, 30351.36], // Wednesday
  [31, 27423.74]  // Thursday
];

const jul23 = [
  [1, 16401.82], // Saturday
  [2, 17767.98], // Sunday
  [3, 26731.63], // Monday
  [4, 29987.42], // Tuesday
  [5, 27892.36], // Wednesday
  [6, 26786.45], // Thursday
  [7, 24033.82], // Friday
  [8, 16074.12], // Saturday
  [9, 17423.98], // Sunday
  [10, 26219.63], // Monday
  [11, 28375.42], // Tuesday
  [12, 27313.86], // Wednesday
  [13, 25269.35], // Thursday
  [14, 24560.82], // Friday
  [15, 15766.12], // Saturday
  [16, 17081.98], // Sunday
  [17, 26694.63], // Monday
  [18, 28805.42], // Tuesday
  [19, 26762.36], // Wednesday
  [20, 24769.25], // Thursday
  [21, 23099.82], // Friday
  [22, 14473.12], // Saturday
  [23, 15732.98], // Sunday
  [24, 25194.63], // Monday
  [25, 27245.42], // Tuesday
  [26, 26234.36], // Wednesday
  [27, 24278.15], // Thursday
  [28, 22653.82], // Friday
  [29, 14192.12], // Saturday
  [30, 15447.98],  // Sunday
  [31, 24447.98]  // Monday
];

const dp1 = [
  [0,53],
  [1,50],
  [2,49],
  [3,47],
  [4,49],
  [5,50],
  [6,48],
  [7,48],
  [8,53],
  [9,52],
  [10,49],
  [11,50],
  [12,48],
  [13,44],
  [14,40],
  [15,41],
  [16,45],
  [17,44],
  [18,41],
  [19,38],
  [20,39],
  [21,41],
  [22,39],
  [23,35],
  [24,38],
  [25,38],
  [26,40],
  [27,38],
  [28,42],
  [29,46],
  [30,43],
  [31,40],
  [32,36],
  [33,31],
  [34,28],
  [35,29],
  [36,29],
  [37,33],
  [38,37],
  [39,35],
  [40,37],
  [41,39],
  [42,39],
  [43,34],
  [44,37],
  [45,39],
  [46,38],
  [47,37],
  [48,40],
  [49,35],
  [50,31],
  [51,31],
  [52,30],
  [53,25],
  [54,28],
  [55,28],
  [56,30],
  [57,32],
  [58,32],
  [59,37],
  [60,35],
  [61,39],
  [62,41],
  [63,41],
  [64,43],
  [65,39],
  [66,39],
  [67,43],
  [68,42],
  [69,43],
  [70,38],
  [71,43],
  [72,41],
  [73,44],
  [74,46],
  [75,47],
  [76,49],
  [77,46],
  [78,51],
  [79,50],
  [80,53],
  [81,56],
  [82,52],
  [83,56],
  [84,60],
  [85,58],
  [86,56],
  [87,55],
  [88,54],
  [89,54],
  [90,58],
  [91,57],
  [92,60],
  [93,54],
  [94,56],
  [95,55],
  [96,54],
  [97,52],
  [98,54],
  [99,54],
  [100,51],
  [101,51],
  [102,46],
  [103,48]
];

const dp2 = [
  [0,56],
  [1,54],
  [2,59],
  [3,61],
  [4,65],
  [5,70],
  [6,73],
  [7,70],
  [8,73],
  [9,69],
  [10,73],
  [11,69],
  [12,73],
  [13,77],
  [14,72],
  [15,75],
  [16,71],
  [17,69],
  [18,67],
  [19,68],
  [20,67],
  [21,66],
  [22,61],
  [23,58],
  [24,56],
  [25,53],
  [26,52],
  [27,57],
  [28,59],
  [29,63],
  [30,60],
  [31,62],
  [32,64],
  [33,67],
  [34,66],
  [35,67],
  [36,71],
  [37,66],
  [38,64],
  [39,62],
  [40,66],
  [41,65],
  [42,62],
  [43,66],
  [44,63],
  [45,66],
  [46,64],
  [47,65],
  [48,69],
  [49,65],
  [50,69],
  [51,65],
  [52,68],
  [53,73],
  [54,71],
  [55,71],
  [56,75],
  [57,77],
  [58,81],
  [59,79],
  [60,76],
  [61,74],
  [62,75],
  [63,76],
  [64,78],
  [65,81],
  [66,82],
  [67,87],
  [68,85],
  [69,82],
  [70,82],
  [71,77],
  [72,80],
  [73,84],
  [74,83],
  [75,88],
  [76,89],
  [77,88],
  [78,83],
  [79,87],
  [80,85],
  [81,85],
  [82,90],
  [83,92],
  [84,97],
  [85,80],
  [86,82],
  [87,81],
  [88,84],
  [89,85],
  [90,90],
  [91,91],
  [92,90],
  [93,89],
  [94,86],
  [95,83],
  [96,82],
  [97,87],
  [98,85],
  [99,87],
  [100,82],
  [101,87],
  [102,86],
  [103,83]
];

const dp3 = [
  [0,40],
  [1,39],
  [2,35],
  [3,33],
  [4,28],
  [5,28],
  [6,24],
  [7,20],
  [8,17],
  [9,16],
  [10,19],
  [11,16],
  [12,13],
  [13,18],
  [14,17],
  [15,18],
  [16,18],
  [17,19],
  [18,18],
  [19,17],
  [20,20],
  [21,18],
  [22,17],
  [23,17],
  [24,15],
  [25,15],
  [26,14],
  [27,15],
  [28,18],
  [29,19],
  [30,23],
  [31,27]
];

const dp4 = [[0,-2],[1,-3],[2,7],[3,-8],[4,-2],[5,10],[6,12],[7,3],[8,-5],[9,-6],[10,-1],[11,-8],[12,18],[13,7],[14,-3],[15,-2],[16,3],[17,19],[18,-10],[19,10],[20,11],[21,-7],[22,-5],[23,-4],[24,17],[25,11],[26,16],[27,12],[28,-3],[29,-7],[30,-6],[31,4],[32,1],[33,7],[34,2],[35,6],[36,10],[37,-3],[38,10],[39,9]];
const dp5 = [[0,3],[1,4],[2,-5],[3,12],[4,5],[5,-7],[6,-4],[7,-2],[8,6],[9,10],[10,3],[11,5],[12,-10],[13,-4],[14,4],[15,3],[16,-5],[17,-6],[18,7],[19,-5],[20,-7],[21,5],[22,4],[23,5],[24,-10],[25,-6],[26,-7],[27,-4],[28,5],[29,2],[30,3],[31,-4],[32,-2],[33,-3],[34,-2],[35,-3],[36,-8],[37,4],[38,-2],[39,-3]];

export { jul23, aug23, dp1, dp2, dp3, dp4, dp5 };