import React from "react";
import { Button, Card, Col, Form, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import siteLogo from "../assets/svg/revbox-logo.svg";

export default function Signin() {
  return (
    <div className="page-sign">
      <Card className="card-sign">
        <Card.Header className="pb-3">
          <Link to="/" className="header-logo">
            <object type="image/svg+xml" data={siteLogo} className="w-100" aria-label="svg image"></object>
          </Link>
          <Card.Title>Sign In</Card.Title>
          <Card.Text>Welcome back! Please signin to continue.</Card.Text>
        </Card.Header>
        <Card.Body className="pb-3 pt-3">
          <Form method="get" action="/publisher/dashboard">
            <div className="mb-4">
              <Form.Label >Email address</Form.Label>
              <Form.Control type="text" placeholder="Enter your email address" value="user@revbox.co" />
            </div>
            <div className="mb-4">
              <Form.Label className="d-flex justify-content-between">Password <Link to="">Forgot password?</Link></Form.Label>
              <Form.Control type="password" placeholder="Enter your password" value="password123" />
            </div>
            <Button type="submit" variant="primary" className="btn-sign">Sign In</Button>

            <div className="divider"><span>Subject to Terms & Conditions</span></div>

        
          </Form>
        </Card.Body>
        <Card.Footer className="pt-4">
          Don't have an account? <Link to="/pages/signup">Create One</Link>.
        </Card.Footer>
      </Card>
    </div>
  )
}