import React, { useEffect } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Main from './layouts/Main';
import NotFound from "./pages/NotFound";
import publicRoutes from "./routes/PublicRoutes";
import protectedRoutes from "./routes/ProtectedRoutes";

// import css
import "./assets/css/remixicon.css";

// import scss
import "./scss/style.scss";

// set skin on load
window.addEventListener("load", function () {
  let skinMode = localStorage.getItem("skin-mode");
  let HTMLTag = document.querySelector("html");

  if (skinMode) {
    HTMLTag.setAttribute("data-skin", skinMode);
  }
});

export default function App() {
  const securePassword = "ff{{%mx'I{ly";
  const [password, setPassword] = React.useState("");
  const savedPassword = localStorage.getItem("password");
  const [isAuthenticated, setIsAuthenticated] = React.useState(false);

  useEffect(() => {
    if (savedPassword) {
      if (savedPassword === securePassword) {
        setIsAuthenticated(true);
      }
    }
  });

  const onPasswordSubmit = () => {
    localStorage.setItem("password", password);
    window.location.reload();
  }

  if (!isAuthenticated) {
    return (
      <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100vh" }}>
        <div style={{ width: "100%", maxWidth: "400px" }}>
          <div style={{ padding: "20px", border: "1px solid #ccc", borderRadius: "5px", boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)" }}>
            <h3 style={{ textAlign: "center", marginBottom: "20px" }}>
            🔐 Password Protected
            </h3>
            <input
              type="password"
              value={password}
              style={{ width: "100%", padding: "10px", marginBottom: "10px", borderRadius: "5px", border: "1px solid #ccc" }}
              onChange={(e) => setPassword(e.target.value)}
            />
            <button
              onClick={onPasswordSubmit}
              style={{ width: "100%", padding: "10px", background: "#007bff", color: "#fff", border: "none", borderRadius: "5px" }}
            >
              Submit
            </button>
          </div>
        </div>
      </div>
    )
  }

  return (
    <React.Fragment>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Main />}>
            {protectedRoutes.map((route, index) => {
              return (
                <Route
                  path={route.path}
                  element={route.element}
                  key={index}
                />
              )
            })}
          </Route>
          {publicRoutes.map((route, index) => {
            return (
              <Route
                path={route.path}
                element={route.element}
                key={index}
              />
            )
          })}
          <Route path="*" element={<NotFound />} />
        </Routes>
      </BrowserRouter>
    </React.Fragment>
    
  );
}