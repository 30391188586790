import React, { useState } from "react";
import { Card, Col, Nav, OverlayTrigger, Row, Table, Button, Tooltip } from "react-bootstrap";
import { Link } from "react-router-dom";
import Header from "../layouts/Header";
import Footer from "../layouts/Footer";

export default function Home() {

  const currentSkin = (localStorage.getItem('skin-mode'))? 'dark' : '';
  const [skin, setSkin] = useState(currentSkin);

  return (
    <React.Fragment>
      <Header onSkin={setSkin} />
      <div className="main main-app p-3 p-lg-4">
        <div className="d-flex align-items-center justify-content-between mb-4">
          <div>
            <ol className="breadcrumb fs-sm mb-1">
              <li className="breadcrumb-item"><Link to="#">ADV Home</Link></li>
            </ol>
            <h4 className="main-title mb-0">Dashboard</h4>
          </div>

          <Nav as="nav" className="nav-icon nav-icon-lg">
            <OverlayTrigger overlay={<Tooltip>Print</Tooltip>}>
              <Nav.Link href=""><i className="ri-printer-line"></i></Nav.Link>
            </OverlayTrigger>
            <OverlayTrigger overlay={<Tooltip>Report</Tooltip>}>
              <Nav.Link href=""><i className="ri-bar-chart-2-line"></i></Nav.Link>
            </OverlayTrigger>
          </Nav>
        </div>

        <Row className="g-3">
          <Col xl="12">
            <Card className="card-one">
              <Card.Header>
                <Card.Title as="h6">ABC Bank Campaign</Card.Title>
                <Nav className="nav-icon nav-icon-sm ms-auto">
                  <Nav.Link href=""><i className="ri-refresh-line"></i></Nav.Link>
                  <Nav.Link href=""><i className="ri-more-2-fill"></i></Nav.Link>
                </Nav>
              </Card.Header>
              <Card.Body>
                <div className="p-2">
                  <Row className="g-3">
                    <Col sm="3">
                      <h3 className="card-value fw-bold mb-1 text-success">ACTIVE</h3>
                      <label className="card-title fw-semibold text-dark mb-1">Campaign Status</label>
                      <p className="mb-0 fs-xs text-secondary">Your campaign will pause when you hit your budget. | <Link to="/advertiser/campaign/ppc" className="">Campaign Management</Link></p>
                    </Col>
                    <Col sm="3">
                      <h3 className="card-value mb-1"><span>$</span>8,327.00</h3>
                      <label className="card-title fw-semibold text-dark mb-1">Spend Since Last Invoice</label>
                      <p className="mb-0 fs-xs text-secondary">This spend will be invoiced on your next billing date. | <Link to="/advertiser/campaign/analytics" className="">Analytics</Link> | <Link to="/advertiser/campaign/invoices" className="">Invoices</Link></p>
                    </Col>
                    <Col sm="3">
                      <h3 className="card-value mb-1">1,340</h3>
                      <label className="card-title fw-semibold text-dark mb-1">Clicks Since Last Invoice</label>
                      <p className="mb-0 fs-xs text-secondary">The clicks that you received since your last invoice. | <Link to="/advertiser/campaign/analytics" className="">Analytics</Link> | <Link to="/advertiser/campaign/invoices" className="">Invoices</Link></p>
                    </Col>
                    <Col sm="3">
                      <h3 className="card-value mb-1">1st Sep 2023</h3>
                      <label className="card-title fw-semibold text-dark mb-1">Next Billing Date</label>
                      <p className="mb-0 fs-xs text-secondary">This spend will be invoiced on your next billing date. | <Link to="/advertiser/campaign/analytics" className="">Analytics</Link> | <Link to="/advertiser/campaign/invoices" className="">Invoices</Link></p>
                    </Col>
                    <Col sm="3" className="d-none d-sm-block">
                    </Col>
                    <Col sm="3">
                      <h3 className="card-value mb-1 text-danger"><span>$</span>12,105.00</h3>
                      <label className="card-title fw-semibold text-dark mb-1">Unpaid Invoices</label>
                      <p className="mb-0 fs-xs text-secondary">It can take a few days for payments to be reconciled. | <Link to="" className="">Invoices</Link></p>
                    </Col>
                    <Col sm="3">
                      <h3 className="card-value mb-1">143,521</h3>
                      <label className="card-title fw-semibold text-dark mb-1">Impressions Since Last Invoice</label>
                      <p className="mb-0 fs-xs text-secondary">The impressions you received since your last invoice.  | <Link to="/advertiser/campaign/analytics" className="">Analytics</Link> | <Link to="/advertiser/campaign/invoices" className="">Invoices</Link></p>
                    </Col>
                    <Col sm="3">
                      <h3 className="card-value mb-1">9</h3>
                      <label className="card-title fw-semibold text-dark mb-1">Live Inventory Bids</label>
                      <p className="mb-0 fs-xs text-secondary">Live bids on current inventory associated to this campaign. | <Link to="/advertiser/campaign/ppc" className="">Campaign Management</Link></p>
                    </Col>
                  </Row>
                </div>
              </Card.Body>
            </Card>
          </Col>
          <Col xl="12">
            <Button href="/advertiser/campaign/new" className="" variant="outline-primary"><i class="ri-add-line"></i> New Campaign</Button>
          </Col>
        </Row>
        <Footer />
      </div>
    </React.Fragment>
  )
}