import React, { useEffect, useState } from "react";
import Header from "../../layouts/Header";
import Footer from "../../layouts/Footer";
import { Link } from "react-router-dom";
import { Card, Col, Button, Row } from "react-bootstrap";
import { Grid } from "gridjs-react";

export default function AdvertiserInvoices() {

  const currentSkin = (localStorage.getItem('skin-mode')) ? 'dark' : '';
  const [skin, setSkin] = useState(currentSkin);

  const switchSkin = (skin) => {
    if (skin === 'dark') {
      const btnWhite = document.getElementsByClassName('btn-white');

      for (const btn of btnWhite) {
        btn.classList.add('btn-outline-primary');
        btn.classList.remove('btn-white');
      }
    } else {
      const btnOutlinePrimary = document.getElementsByClassName('btn-outline-primary');

      for (const btn of btnOutlinePrimary) {
        btn.classList.remove('btn-outline-primary');
        btn.classList.add('btn-white');
      }
    }
  };

  switchSkin(skin);

  useEffect(() => {
    switchSkin(skin);
  }, [skin]);

  return (
    <React.Fragment>
      <Header onSkin={setSkin} />
      <div className="main main-app p-3 p-lg-4">

        <div className="d-flex align-items-center justify-content-between mb-4">
          <div>
            <ol className="breadcrumb fs-sm mb-1">
              <li className="breadcrumb-item"><Link href="#">ADV Home</Link></li>
              <li className="breadcrumb-item active" aria-current="page">Invoices</li>
            </ol>
            <h4 className="main-title mb-0">Invoices</h4>
          </div>

        </div>

        <Row className="g-3 justify-content-center">
  
          <Col md="12">
            <Card className="card-one">
              <Card.Body>
                <div className="table-responsive">
                  <Grid
                    data={[
                      ['1', 'ABC Bank', '$35,000.00', 'PAID', '05/08/2023']
                    ]}
                    columns={['ID', 'Campaign', 'Amount', 'Status', 'Date']}
                    search={true}
                    pagination={{
                      enabled: true,
                      limit: 15
                    }}
                    sort={true}
                    className={{
                      table: 'table table-bordered table-hover mb-0'
                    }}
                  />
                </div>
                <Button href="/invoices/invoice" variant="primary">Preview Invoice</Button>
              </Card.Body>
            </Card>
          </Col>

        </Row>

        <Footer />
      </div>
    </React.Fragment>
  )
}