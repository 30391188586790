import React, { useEffect, useState } from "react";
import Header from "../../layouts/Header";
import Footer from "../../layouts/Footer";
import { Link } from "react-router-dom";
import { Button, Card, Col, Nav, OverlayTrigger, ProgressBar, Row, Table, Tooltip } from "react-bootstrap";
import { dp1, dp2 } from "../../data/DashboardData";
import ReactApexChart from "react-apexcharts";
import { VectorMap } from "@react-jvectormap/core";
import { worldMill } from "@react-jvectormap/world";

export default function AdvertiserAnalytics() {

  var data = [[0, 9], [1, 7], [2, 4], [3, 8], [4, 4], [5, 12], [6, 4], [7, 6], [8, 5], [9, 10], [10, 4], [11, 5], [12, 10], [13, 2], [14, 6]];

  const chart = {
    parentHeightOffset: 0,
    stacked: true,
    sparkline: {
      enabled: true
    }
  };

  const states = {
    hover: {
      filter: {
        type: 'none'
      }
    },
    active: {
      filter: {
        type: 'none'
      }
    }
  };

  const plotOptions = {
    bar: {
      columnWidth: '60%'
    },
  };

  const stroke = {
    curve: 'straight',
    lineCap: 'square'
  };

  const seriesOne = [{
    type: 'column',
    data: [[0, 0], [1, 0], [2, 5], [3, 10], [4, 6], [5, 10], [6, 15], [7, 18], [8, 7], [9, 11], [10, 13], [11, 15], [12, 13], [13, 7], [14, 5]]
  }, {
    type: 'column',
    data: data
  }];
  
  const seriesFour = [
    {
      name: "Clicks",
      type: "bar",
      data: Array.from({length: 31}, () => Math.floor(100 + Math.random() * 100))
    }, 
    {
      name: "Impressions",
      type: "area",
      data: Array.from({length: 31}, () => Math.floor(2000 + Math.random() * 2000))
    }, 
    {
      name: "Campaign Spend",
      type: "line",
      data: Array.from({length: 31}, () => Math.floor(800 + Math.random() * 800))
    }
  ];
  
  const optionFour = {
      chart: {
          parentHeightOffset: 0,
          stacked: false,
          toolbar: { show: false },
          animations: {
              enabled: true,
          },
      },
      grid: {
          borderColor: 'rgba(72,94,144, 0.07)',
          padding: {
              top: -20,
              left: 5
          }
      },
      colors: ['#3670e4', 'rgba(125, 125, 125, 0.25)', '#0cb785'],  // added a color for the new series
      plotOptions: {
          bar: { columnWidth: '50%' },
      },
      stroke: {
          curve: ['straight', 'smooth', 'smooth'], // added a curve type for the new series
          width: [0, 2, 2] // added a width for the new series
      },
      fill: {
          type: ['solid', 'gradient', 'solid'], // added a fill type for the new series
          opacity: 1,
          gradient: {
              type: "vertical",
              shadeIntensity: 1,
              opacityFrom: 0.5,
              opacityTo: 0.1,
              stops: [0, 100]
          }
      },
      xaxis: {
        categories: Array.from({length: 31}, (_, i) => `Aug ${i + 1}`), 
        labels: {
          style: {
            colors: '#ccc',  // update this line
            fontSize: '11px'
          }
        }
      },
      yaxis: [
          {
              seriesName: 'Clicks',
              opposite: true,
              tickAmount: 8,
              max: 400,
              labels: {
                  style: {
                      colors: ['#2845f9'],
                      fontSize: '11px',
                      fontWeight: 'bold'
                  },
                  formatter: function (val) {
                    return val + " clicks";
                  }
              }
          },
          {
              seriesName: 'Impressions',
              opposite: false,
              tickAmount: 8,
              labels: {
                  show: false,
                  style: {
                      colors: ['#ccc'],
                      fontSize: '11px'
                  }
              }
          },
          {
              seriesName: 'Campaign Spend',
              opposite: false,
              tickAmount: 8,
              max: 3000,
              labels: {
                style: {
                    colors: ['#0cb785'],
                    fontSize: '11px',
                    fontWeight: 'bold'
                },
                formatter: function (val) {
                  return "$" + val + ".00";
                }
            }
          }
      ],
      dataLabels: { enabled: false },
      tooltip: {
        shared: true,
        intersect: false,
        y: {
          formatter: function (value, { seriesIndex }) {
            if(seriesIndex === 2) {
              return '$' + value.toFixed(0) + '.00';
            }
            return value.toFixed(0);
          }
        }
      },
      legend: { show: true }
  };

  const regStyle = {
    selected: {
      fill: "#2845f9"
    },
    initial: {
      fill: "rgba(217, 221, 231, 0.5)"
    }
  };

  const currentSkin = (localStorage.getItem('skin-mode')) ? 'dark' : '';
  const [skin, setSkin] = useState(currentSkin);

  const switchSkin = (skin) => {
    if (skin === 'dark') {
      const btnWhite = document.getElementsByClassName('btn-white');

      for (const btn of btnWhite) {
        btn.classList.add('btn-outline-primary');
        btn.classList.remove('btn-white');
      }
    } else {
      const btnOutlinePrimary = document.getElementsByClassName('btn-outline-primary');

      for (const btn of btnOutlinePrimary) {
        btn.classList.remove('btn-outline-primary');
        btn.classList.add('btn-white');
      }
    }
  };

  switchSkin(skin);

  useEffect(() => {
    switchSkin(skin);
  }, [skin]);

  return (
    <React.Fragment>
      <Header onSkin={setSkin} />
      <div className="main main-app p-3 p-lg-4">

        <div className="d-flex align-items-center justify-content-between mb-4">
          <div>
            <ol className="breadcrumb fs-sm mb-1">
              <li className="breadcrumb-item"><Link href="#">ADV Home</Link></li>
              <li className="breadcrumb-item">ABC Bank</li>
              <li className="breadcrumb-item active" aria-current="page">Campaign Analytics</li>
            </ol>
            <h4 className="main-title mb-0">Campaign Analytics</h4>
          </div>

          <Nav as="nav" className="nav-icon nav-icon-lg">
            <OverlayTrigger overlay={<Tooltip>Share</Tooltip>}>
              <Nav.Link href=""><i className="ri-share-line"></i></Nav.Link>
            </OverlayTrigger>
            <OverlayTrigger overlay={<Tooltip>Print</Tooltip>}>
              <Nav.Link href=""><i className="ri-printer-line"></i></Nav.Link>
            </OverlayTrigger>
            <OverlayTrigger overlay={<Tooltip>Report</Tooltip>}>
              <Nav.Link href=""><i className="ri-bar-chart-2-line"></i></Nav.Link>
            </OverlayTrigger>
          </Nav>
        </div>

        <Row className="g-3 justify-content-center">
          <Col md="6" xl="4">
            <Card className="card-one">
              <Card.Body>
                <Row>
                  <Col xs="12">
                    <h3 className="card-value mb-1">$<b>34,608.50</b></h3>
                    <label className="card-title fw-medium text-dark mb-1">Campaign Balance</label>
                    <span className="d-block text-muted fs-11 ff-secondary lh-4">Your current outstanding campaign balance</span>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
          <Col md="6" xl="4">
            <Card className="card-one">
              <Card.Body>
                <Row>
                  <Col xs="12">
                    <h3 className="card-value mb-1">$0.00</h3>
                    <label className="card-title fw-medium text-dark mb-1">Campaign Credits</label>
                    <span className="d-block text-muted fs-11 ff-secondary lh-4">Credits can be applied against future campaign spend</span>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
          <Col md="6" xl="4">
            <Card className="card-one">
              <Card.Body>
                <Row>
                  <Col xs="12">
                    <h3 className="card-value mb-1">1st Aug 2023 - 31st Aug 2023</h3>
                    <label className="card-title fw-medium text-dark mb-1">Selected Time Period</label>
                    <span className="d-block text-muted fs-11 ff-secondary lh-4">Select a period to visualise the campaign spend</span>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
          <Col md="12" xl="12">
            <Card className="card-one">
              <Card.Header>
                <Card.Title as="h6">Impressions &amp; Clicks</Card.Title>
                <Nav className="nav-icon nav-icon-sm ms-auto">
                  <Nav.Link href=""><i className="ri-refresh-line"></i></Nav.Link>
                  <Nav.Link href=""><i className="ri-more-2-fill"></i></Nav.Link>
                </Nav>
              </Card.Header>
              <Card.Body>
                <ReactApexChart series={seriesFour} options={optionFour} type="bar" height={300} className="apex-chart-nine" />
              </Card.Body>
            </Card>
          </Col>
          <Col md="5" xl="4" style={{display:"none"}}>
            <Card className="card-one">
              <Card.Header>
                <Card.Title as="h6">Campaign Performance Score</Card.Title>
                <Nav className="nav-icon nav-icon-sm ms-auto">
                  <Nav.Link href=""><i className="ri-refresh-line"></i></Nav.Link>
                  <Nav.Link href=""><i className="ri-more-2-fill"></i></Nav.Link>
                </Nav>
              </Card.Header>
              <Card.Body className="p-3">>
                <h2 className="performance-value mb-0">7.4 <small className="text-success d-flex align-items-center"><i className="ri-arrow-up-line"></i> 2.8%</small></h2>

                <label className="card-title fs-sm fw-medium">Performance Potential Score</label>

                <ProgressBar className="progress-one ht-8 mt-2 mb-4">
                  <ProgressBar now={50} />
                  <ProgressBar now={25} variant="success" />
                  <ProgressBar now={5} variant="orange" />
                  <ProgressBar now={5} variant="pink" />
                  <ProgressBar now={10} variant="info" />
                  <ProgressBar now={5} variant="indigo" />
                </ProgressBar>

                <Table className="table-three">
                  <tbody>
                    {[
                      {
                        "dot": "primary",
                        "label": "Impressions on #1",
                        "count": "3,007",
                        "percent": "50"
                      }, {
                        "dot": "success",
                        "label": "Impressions on #2-3",
                        "count": "1,674",
                        "percent": "25"
                      }, {
                        "dot": "orange",
                        "label": "Impressions on #4-7",
                        "count": "125",
                        "percent": "6"
                      }, {
                        "dot": "pink",
                        "label": "Impressions on #7-10",
                        "count": "98",
                        "percent": "5"
                      }, {
                        "dot": "info",
                        "label": "Impressions on #10-15",
                        "count": "512",
                        "percent": "10"
                      }, {
                        "dot": "indigo",
                        "label": "Impressions on #15+",
                        "count": "81",
                        "percent": "4"
                      }
                    ].map((item, index) => (
                      <tr key={index}>
                        <td><div className={"badge-dot bg-" + item.dot}></div></td>
                        <td>{item.label}</td>
                        <td>{item.count}</td>
                        <td>{item.percent}%</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>

              </Card.Body>
            </Card>
          </Col>
          
          <Col md="12">
            <Card className="card-one">
              <Card.Header>
                <Card.Title as="h6">Inventory Stats</Card.Title>
                <Nav className="nav-icon nav-icon-sm ms-auto">
                  <Nav.Link href=""><i className="ri-refresh-line"></i></Nav.Link>
                  <Nav.Link href=""><i className="ri-more-2-fill"></i></Nav.Link>
                </Nav>
              </Card.Header>
              <Card.Body>
                <Table className="table-four table-bordered">
                  <thead>
                    <tr>
                      <th colSpan="4">Inventory</th>
                      <th colSpan="4">1st Aug 2023 - 31st Aug 2023</th>
                    </tr>
                    <tr>
                      <th>Name</th>
                      <th>Categories</th>
                      <th>Type</th>
                      <th>Website</th>
                      <th>Impressions</th>
                      <th>Clicks</th>
                      <th>Average CPC</th>
                      <th>Total Spend</th>
                    </tr>
                  </thead>
                  <tbody>
                    {[
                      {
                        "name": "Top List Name",
                        "categories": "Trade Finance",
                        "type": "Top List",
                        "site": "TFG",
                        "impressions": "5,628",
                        "clicks": "43",
                        "avcpc": "$17.50",
                        "totalcost": "$752.50"
                      },
                      {
                        "name": "Top List Name",
                        "categories": "Trade Finance",
                        "type": "Top List",
                        "site": "TFG",
                        "impressions": "5,628",
                        "clicks": "43",
                        "avcpc": "$17.50",
                        "totalcost": "$752.50"
                      },
                      {
                        "name": "Top List Name",
                        "categories": "Trade Finance",
                        "type": "Single Banner",
                        "site": "TFG",
                        "impressions": "5,628",
                        "clicks": "43",
                        "avcpc": "$17.50",
                        "totalcost": "$752.50"
                      },
                      {
                        "name": "Top List Name",
                        "categories": "Trade Finance",
                        "type": "Top List",
                        "site": "TFG",
                        "impressions": "5,628",
                        "clicks": "43",
                        "avcpc": "$17.50",
                        "totalcost": "$752.50"
                      },
                      {
                        "name": "Top List Name",
                        "categories": "Trade Finance",
                        "type": "Multi-Item Banner",
                        "site": "TFG",
                        "impressions": "5,628",
                        "clicks": "43",
                        "avcpc": "$17.50",
                        "totalcost": "$752.50"
                      }
                    ].map((item, index) => (
                      <tr key={index}>
                        <td><Link to="">{item.name}</Link></td>
                        <td>{item.categories}</td>
                        <td>{item.type}</td>
                        <td>{item.site}</td>
                        <td>{item.impressions}</td>
                        <td>{item.clicks}</td>
                        <td>{item.avcpc}</td>
                        <td>{item.totalcost}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </Card.Body>
            </Card>
          </Col>

          <Col md="12">
            <Card className="card-one">
              <Card.Header>
                <Card.Title as="h6">Clicks By Country</Card.Title>
                <Nav className="nav-icon nav-icon-sm ms-auto">
                  <Nav.Link href=""><i className="ri-refresh-line"></i></Nav.Link>
                  <Nav.Link href=""><i className="ri-more-2-fill"></i></Nav.Link>
                </Nav>
              </Card.Header>
              <Card.Body className="p-4">
                <Row className="align-items-center g-3">
                  <Col md="4" className="d-flex flex-column justify-content-center">
                    <Table className="table-one mb-4">
                      <thead>
                        <tr>
                          <th className="wd-40 pt-0">Your Top Countries</th>
                          <th className="wd-60 pt-0">&nbsp;</th>
                        </tr>
                      </thead>
                      <tbody>
                        {[
                          {
                            "bg": "twitter",
                            "country": "United States",
                            "amount": "1,040"
                          }, {
                            "bg": "primary",
                            "country": "India",
                            "amount": "460"
                          }, {
                            "bg": "teal",
                            "country": "Australia",
                            "amount": "380"
                          }, {
                            "bg": "danger",
                            "country": "China",
                            "amount": "203"
                          }, {
                            "bg": "orange",
                            "country": "Brazil",
                            "amount": "124"
                          }, {
                            "bg": "info",
                            "country": "Japan",
                            "amount": "42"
                          }, {
                            "bg": "warning",
                            "country": "Saudi Arabia",
                            "amount": "5"
                          }
                        ].map((item, index) => (
                          <tr key={index}>
                            <td>
                              <span className={"badge-dot me-2 bg-" + item.bg}></span> <span className="fw-medium">{item.country}</span>
                            </td>
                            <td>{item.amount}</td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>

                    <Button variant="white" className="btn-sm">Show Full Report</Button>
                  </Col>
                  <Col md="8" className="mt-5 mt-md-0">
                    <VectorMap map={worldMill} backgroundColor={["transparent"]} regionStyle={regStyle} selectedRegions={["US", "IN", "AU", "BR", "CN", "JP", "SA"]} className="vmap-one" />
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
        </Row>

        <Footer />
      </div>
    </React.Fragment>
  )
}