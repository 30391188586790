import React, { useEffect, useState } from "react";
import Header from "../../layouts/Header";
import Footer from "../../layouts/Footer";
import { Link } from "react-router-dom";
import { Button, ListGroup, Card, Col, Nav, OverlayTrigger, Form, Badge, Carousel, Row, Table, Tooltip } from "react-bootstrap";
import { dp1, dp2 } from "../../data/DashboardData";
import ReactApexChart from "react-apexcharts";
import { VectorMap } from "@react-jvectormap/core";
import { worldMill } from "@react-jvectormap/world";
import { Grid } from "gridjs-react";


import img2 from "../../assets/img/img2.jpg";
import simpleTopList from "../../assets/svg/simple_top_list.svg";
import topListOverview from "../../assets/svg/top_list_overview.svg";
import simpleDynamicBanner from "../../assets/svg/simple_dynamic_banner.svg";
import simpleStaticBanner from "../../assets/svg/simple_static_banner.svg";
import dynamic3PackBanner from "../../assets/svg/dynamic_3pack_banner.svg";
import dynamicAIContent from "../../assets/svg/dynamic_ai_content.svg";

export default function PublisherInventoryTypeCreate() {

  const currentSkin = (localStorage.getItem('skin-mode')) ? 'dark' : '';
  const [skin, setSkin] = useState(currentSkin);

  const switchSkin = (skin) => {
    if (skin === 'dark') {
      const btnWhite = document.getElementsByClassName('btn-white');

      for (const btn of btnWhite) {
        btn.classList.add('btn-outline-primary');
        btn.classList.remove('btn-white');
      }
    } else {
      const btnOutlinePrimary = document.getElementsByClassName('btn-outline-primary');

      for (const btn of btnOutlinePrimary) {
        btn.classList.remove('btn-outline-primary');
        btn.classList.add('btn-white');
      }
    }
  };

  switchSkin(skin);

  useEffect(() => {
    switchSkin(skin);
  }, [skin]);

  return (
    <React.Fragment>
      <Header onSkin={setSkin} />
      <div className="main main-app p-3 p-lg-4">

        <div className="d-flex align-items-center justify-content-between">
          <div>
            <ol className="breadcrumb fs-sm mb-1">
              <li className="breadcrumb-item"><Link href="#">PUB Home</Link></li>
              <li className="breadcrumb-item active" aria-current="page">Create New Inventory</li>
            </ol>
            <h4 className="main-title mb-0">Create New Inventory</h4>
          </div>
          
        </div>

        <Row className="g-3 justify-content-center">
          <Col md="12">
            <Row>
              <Col xs="12" sm="6" lg="3" xl="3">
                <Card className="mt-4">
                  <Card.Img type="image/svg+xml" src={simpleTopList} variant="top" />
                  <Card.Body>
                    <Card.Title className="text-dark">Simple Top List</Card.Title>
                    <Card.Subtitle className="mb-2 text-muted">1 embed block</Card.Subtitle>
                    <Card.Text>Simple Top List block with custom number of products. Compatible with live or interval auctions.</Card.Text>
                  </Card.Body>
                  <Card.Footer>
                    <Button href="/publisher/inventory/new" variant="primary">Create Inventory</Button>
                    <Card.Link href="/publisher/inventory/new" className="ms-3">Customise</Card.Link>
                  </Card.Footer>
                </Card>
              </Col>
              <Col xs="12" sm="6" lg="3" xl="3">
                <Card className="mt-4">
                  <Card.Img type="image/svg+xml" src={topListOverview} variant="top" />
                  <Card.Body>
                    <Card.Title className="text-dark">Top List + Product Overview</Card.Title>
                    <Card.Subtitle className="mb-2 text-muted">2 embed blocks</Card.Subtitle>
                    <Card.Text>Simple Top List & Overview blocks that stay in sync. Compatible with live or interval auctions.</Card.Text>
                  </Card.Body>
                  <Card.Footer>
                    <Button href="/publisher/inventory/new" variant="primary">Create Inventory</Button>
                    <Card.Link href="#" className="ms-3">Customise</Card.Link>
                  </Card.Footer>
                </Card>
              </Col>
              <Col xs="12" sm="6" lg="3" xl="3">
                <Card className="mt-4">
                  <Card.Img type="image/svg+xml" src={simpleDynamicBanner} variant="top" />
                  <Card.Body>
                    <Card.Title className="text-dark">Simple Dynamic Banner</Card.Title>
                    <Card.Subtitle className="mb-2 text-muted">1 embed block</Card.Subtitle>
                    <Card.Text>Simple Dynamic Banner block. Compatible with live or interval auctions.</Card.Text>
                  </Card.Body>
                  <Card.Footer>
                    <Button href="/publisher/inventory/new" variant="primary">Create Inventory</Button>
                    <Card.Link href="#" className="ms-3">Customise</Card.Link>
                  </Card.Footer>
                </Card>
              </Col>
              <Col xs="12" sm="6" lg="3" xl="3">
                <Card className="mt-4">
                  <Card.Img type="image/svg+xml" src={simpleStaticBanner} variant="top" />
                  <Card.Body>
                    <Card.Title className="text-dark">Simple Static Banner</Card.Title>
                    <Card.Subtitle className="mb-2 text-muted">1 embed block</Card.Subtitle>
                    <Card.Text>Custom assets with flexible dimensions. Compatible with interval auctions only.</Card.Text>
                  </Card.Body>
                  <Card.Footer>
                    <Button href="/publisher/inventory/new" variant="primary">Create Inventory</Button>
                    <Card.Link href="#" className="ms-3">Customise</Card.Link>
                  </Card.Footer>
                </Card>
              </Col>
              <Col xs="12" sm="6" lg="3" xl="3">
                <Card className="mt-4">
                  <Card.Img type="image/svg+xml" src={dynamic3PackBanner} variant="top" />
                  <Card.Body>
                    <Card.Title className="text-dark">Multi-Item Dynamic Banner</Card.Title>
                    <Card.Subtitle className="mb-2 text-muted">1 embed block</Card.Subtitle>
                    <Card.Text>Dynamic Multi-Item Banner blocks. Compatible with live or interval auctions.</Card.Text>
                  </Card.Body>
                  <Card.Footer>
                    <Button href="/publisher/inventory/new" variant="primary">Create Inventory</Button>
                    <Card.Link href="#" className="ms-3">Customise</Card.Link>
                  </Card.Footer>
                </Card>
              </Col>
              <Col xs="12" sm="6" lg="3" xl="3">
                <Card className="mt-4">
                  <Card.Img type="image/svg+xml" src={dynamicAIContent} variant="top" />
                  <Card.Body>
                    <Card.Title className="text-dark">Dynamic AI Content</Card.Title>
                    <Card.Subtitle className="mb-2 text-muted">1 or more embed blocks</Card.Subtitle>
                    <Card.Text>Dynamic AI content blocks. Compatible with live or interval auctions.</Card.Text>
                  </Card.Body>
                  <Card.Footer>
                    <Button href="/publisher/inventory/new" variant="primary">Create Inventory</Button>
                    <Card.Link href="#" className="ms-3">Customise</Card.Link>
                  </Card.Footer>
                </Card>
              </Col>
            </Row>
          </Col>

        </Row>

        <Footer />
      </div>
    </React.Fragment>
  )
}